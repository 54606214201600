import React, { useState, useEffect, useContext, useMemo } from "react"
import { navigate, useStaticQuery, Link, graphql } from "gatsby"
import { useQueryCache, useQuery, useMutation } from "react-query"
import { useToasts } from "react-toast-notifications"
import styled from "styled-components"
import Img from "gatsby-image"

import Profile from "../components/profile"
import PrivateRoute from "../components/private-route"
import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import { safeVerifyError } from "../fauna/helpers/errors"
import DecksBar from "../components/decksBar"
import UIContextImported from "./../context/ui"
import { logoutBecauseUnauthorized } from "../fauna/helpers/util"
import DeckRow from "./../components/DeckRow"

import svgpatreon from "./../assets/svgpatreon"
import svgtwitch from "./../assets/svgtwitch"
import svgfacebook from "./../assets/svgfacebook"
import svgtwitter from "./../assets/svgtwitter"
import svgyoutube from "./../assets/svgyoutube"
import svgheart from "../assets/svgheart"
import Spinner from "../components/Spinner"
import SEO from "./../components/seo"

function calculateCosmeticsPool(...args) {
  return args.flat()
}

const User = props => {
  // eq: options.name (gatsby-config)
  const data = useStaticQuery(graphql`
    query {
      m15tokensQ: allFile(
        filter: { sourceInstanceName: { eq: "m15tokensFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      m15tokensnyxQ: allFile(
        filter: { sourceInstanceName: { eq: "m15tokensnyxFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      moldtokensQ: allFile(
        filter: { sourceInstanceName: { eq: "moldtokensFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      artsCutQ: allFile(
        filter: { sourceInstanceName: { eq: "artsCutFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const allCosmeticsFromGatsby = useMemo(
    () =>
      calculateCosmeticsPool(
        data.moldtokensQ.edges,
        data.m15tokensnyxQ.edges,
        data.m15tokensQ.edges,
        data.artsCutQ.edges
      ),
    [data]
  )

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const { addToast } = useToasts()

  const UIContext = useContext(UIContextImported)
  const { state: uistate, dispatch: uidispatch } = UIContext
  const {
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF,
  } = uistate

  const queryCache = useQueryCache()

  const [followUser, followUserInfo] = useFollowUser(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )

  const queryInfo = use_get_decks_where_alias(
    queryCache,
    props.params["*"],
    addToast,
    logoutBecauseUnauthorized,
    sessionContext,
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF
  )
  /*   console.log("🚀🚀/🚀🚀/user/use_get_decks_where_alias/queryInfo: ", queryInfo) */

  const RQ_get_user_info = use_get_user_info(
    queryCache,
    props.params["*"],
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log("user/RQ_get_user_info: ", RQ_get_user_info) */

  useEffect(() => {
    if (!user && faunaQueries.getUser()) {
      sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
    }
    return function cleanup() {
      /*       console.log("user.js/unmounting") */
    }
  }, [props.params["*"], user, sessionContext])

  return (
    <>
      <SEO
        slug="/mtg-deck-builder/"
        title="A community of users interested in deck building"
        description="MTG Builders is a MTG Deck Builder web app, you are what makes it a community. Follow this user on Twitter, Facebook, Twitch, Patreon, Youtube"
      />
      <Profile />
      <BackgroundRadial>
        <DecksBar />
        {queryInfo.isLoading ? (
          <Spinner />
        ) : queryInfo.isSuccess && queryInfo.data?.myError ? (
          <h1>ERROR U001</h1>
        ) : queryInfo.isSuccess && !queryInfo.data ? (
          <h1>ERROR U002</h1>
        ) : queryInfo.isSuccess && queryInfo.data ? (
          <PageLayout>
            <Left_Profile>
              <Box
                myProfileCardShape={RQ_get_user_info.data?.shape}
                userAlias={RQ_get_user_info.data?.alias}
              >
                <GatsbyImgProfile
                  fluid={
                    allCosmeticsFromGatsby.find(
                      edge => edge.node.name === RQ_get_user_info.data?.back
                    )?.node?.childImageSharp?.fluid
                  }
                />
                <WrapGatsbyImgProfileOverlay>
                  <GatsbyImgProfile
                    fluid={
                      allCosmeticsFromGatsby.find(
                        edge =>
                          edge.node.name ===
                          RQ_get_user_info.data?.shape +
                            RQ_get_user_info.data?.sfondo
                      )?.node?.childImageSharp?.fluid
                    }
                  />
                </WrapGatsbyImgProfileOverlay>
              </Box>
              <WrapperLogos>
                <ExtLink
                  style={{
                    "--display": RQ_get_user_info.data?.patreon
                      ? "inline-block"
                      : "none",
                  }}
                  as="a"
                  href={RQ_get_user_info.data?.patreon}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {svgpatreon}
                </ExtLink>

                <ExtLink
                  style={{
                    "--display": RQ_get_user_info.data?.twitch
                      ? "inline-block"
                      : "none",
                  }}
                  as="a"
                  href={RQ_get_user_info.data?.twitch}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {svgtwitch}
                </ExtLink>
                <ExtLink
                  style={{
                    "--display": RQ_get_user_info.data?.youtube
                      ? "inline-block"
                      : "none",
                  }}
                  as="a"
                  href={RQ_get_user_info.data?.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {svgyoutube}
                </ExtLink>
                <ExtLink
                  style={{
                    "--display": RQ_get_user_info.data?.twitter
                      ? "inline-block"
                      : "none",
                  }}
                  as="a"
                  href={"twittoqui"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {svgtwitter}
                </ExtLink>
                <ExtLink
                  style={{
                    "--display": RQ_get_user_info.data?.facebook
                      ? "inline-block"
                      : "none",
                  }}
                  as="a"
                  href={
                    "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/title"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {svgfacebook}
                </ExtLink>
              </WrapperLogos>
              {/*               {user?.alias !== props.params["*"] && (
                <button onClick={e => followUser(props.params["*"])}>
                  {RQ_get_user_info.data?.isFollowedByCurUser ? (
                    <ButtonFollow
                      style={{
                        "--isFollowing":
                          RQ_get_user_info.data?.isFollowedByCurUser, // will be true
                      }}
                      as="button"
                    >
                      {svgheart}
                    </ButtonFollow>
                  ) : (
                    <ButtonFollow
                      style={{
                        "--isFollowing":
                          RQ_get_user_info.data?.isFollowedByCurUser, // will be false
                      }}
                      as="button"
                    >
                      {svgheart} Follow
                    </ButtonFollow>
                  )}
                </button>
              )} */}

              {user?.alias !== props.params["*"] && user?.start_time && (
                <ButtonFollow
                  style={{
                    "--stroke": "#ff974c",
                    "--fill": RQ_get_user_info.data?.isFollowedByCurUser
                      ? "#ff974c"
                      : "none",
                  }}
                  as="button"
                  onClick={e => followUser(props.params["*"])}
                >
                  {svgheart}
                </ButtonFollow>
              )}
              {user?.alias !== props.params["*"] &&
                user?.start_time &&
                !RQ_get_user_info.data?.isFollowedByCurUser && (
                  <Text as="p">Follow</Text>
                )}
            </Left_Profile>
            <Right_Decks>
              {queryInfo.data.data.map(deck => (
                <Link
                  to={`/deck/${deck.deckRef.value.id}`}
                  key={deck.deckRef.value.id}
                >
                  <DeckRow {...deck} />
                </Link>
              ))}
            </Right_Decks>
          </PageLayout>
        ) : queryInfo.isIdle ? (
          <h1>ERROR U003</h1>
        ) : queryInfo.isError ? (
          <h1>ERROR U004</h1>
        ) : (
          <h1>ERROR U005</h1>
        )}
      </BackgroundRadial>
    </>
  )
}

const Text = styled.p`
  margin-top: 5px;
  color: #cccccc;
`

const ButtonFollow = styled.button`
  /* display: var(--display); */
  display: flex;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: center;
  width: 24px;
  height: 24px;
  opacity: 0.8;
  filter: drop-shadow(0px 2px 12px red);

  svg {
    /*     margin-right: 0.5rem;
    margin-bottom: 0.3rem;
    padding: 0.2rem; */
    fill: var(--fill);
    stroke: var(--stroke);
  }

  &:hover {
    svg {
      filter: drop-shadow(0px 4px 6px #ff974c);
    }
  }
`

const BackgroundRadial = styled.div`
  background: radial-gradient(#555, #111);
  min-height: 110vh;
`

const PageLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
`

const Left_Profile = styled.div`
  flex-basis: 29rem; // Where we do not provide a flex-basis value at all, the sidebar’s width is equal to the width of its contents
  flex-grow: 1;

  margin: 0.5rem;

  /* align-self: start;
  position: sticky;
  top: 2rem;
  z-index: 100; */

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  /* border: 1px solid green;
  * {
    border: 1px solid green;
  } */
`
const Right_Decks = styled.div`
  flex-basis: 0;
  flex-grow: 999;
  /* min-width: 72%; */
  /* min-width: calc(50% - 1rem); */
  min-width: calc(70% - 1rem);

  margin: 0.5rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const Box = styled.div`
  /* cursor: pointer; */
  width: 27rem;
  height: 37.5rem;
  perspective: 600px;
  transition: transform 0.25s ease-out;
  position: relative;
  margin-bottom: 3rem;

  filter: drop-shadow(-14px -6px 10px black);

  &:before {
    // pseudoelement non fa confondere Card3D
    content: "${props => props.userAlias}";
    text-align: center;
    position: absolute;
    top: ${props =>
      props.myProfileCardShape === "moldtk" ? "76.8%" : "82.5%"};
    z-index: 1;
    width: 100%;
  }
`

const WrapGatsbyImgProfileOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
`
const GatsbyImgProfile = styled(Img)`
  width: 27rem;
  height: 37.5rem;
  transition: transform 0.25s ease-out;
  border-radius: 1.5rem;
`

const WrapperLogos = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const ExtLink = styled.a`
  /*   display: ${props => (props.href ? "inline-block" : "none")}; */
  display: var(--display);
  margin: 5px;

  &:hover {
    svg {
      filter: drop-shadow(0px 4px 6px #ff974c);
      opacity: 1;
    }
  }

  svg {
    filter: drop-shadow(0px 4px 6px black);
    opacity: 0.5;
  }
`

export default User

function useFollowUser(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useMutation(
    values =>
      fetchFollowUser(
        queryCache,
        values,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      onMutate: values => {
        queryCache.cancelQueries(["QM_get_user_info", values])
        let cached_user_info = queryCache.getQueryData([
          "QM_get_user_info",
          values,
        ])
        queryCache.setQueryData(["QM_get_user_info", values], {
          ...cached_user_info,
          isFollowedByCurUser: !cached_user_info.isFollowedByCurUser,
        })
        return () =>
          queryCache.setQueryData(
            ["QM_get_user_info", values],
            cached_user_info
          )
      },
      onError: (error, values, rollback) => {
        /*         console.log("useFollowUser/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
        if (rollback) {
          rollback()
        }
      },
      onSuccess: (data, values) => {},
      onSettled: (data, error, values, rollback) => {
        queryCache.invalidateQueries(`QM_get_decks_friends`)
        queryCache.invalidateQueries(`QM_get_friends`)
      },
    }
  )
}

async function fetchFollowUser(
  queryCache,
  aliasUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext,
  values
) {
  try {
    const response = await faunaQueries.QM_follow_user(aliasUDF)
    /*     console.log("fetchFollowUser -> response", response) */
    return true
  } catch (err) {
    /*     console.log("fetchFollowUser -> err", err) */
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
    throw err
  }
}

function use_get_decks_where_alias(
  queryCache,
  alias,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext,
  withinDays,
  deckFormat,
  deckColor,
  cardsIncludedObjects,
  cardsExcludedObjects,
  orderByUDF
) {
  const cardsIncluded = cardsIncludedObjects
    .map(cardObj => {
      if (cardObj.isFiltering) {
        return cardObj.name
      }
    })
    .filter(el => el)
  const cardsExcluded = cardsExcludedObjects
    .map(cardObj => {
      if (cardObj.isFiltering) {
        return cardObj.name
      }
    })
    .filter(el => el)
  return useQuery(
    [
      `QM_get_decks_where_alias-${alias}`,
      `QM_get_decks_where_alias-${alias}By${orderByUDF}-${withinDays}-${deckFormat}-${deckColor}-INC:${cardsIncluded}-EXC:${cardsExcluded}`,
    ],
    (prefix, key, after = null) =>
      fetch_get_decks_where_alias(
        alias,
        queryCache,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext,
        after,
        withinDays,
        deckFormat,
        deckColor,
        cardsIncluded,
        cardsExcluded,
        orderByUDF
      ),
    {
      staleTime: 0,
      /* keepPreviousData: true, */

      onError: (error, values, rollback) => {
        /*         console.log("use_get_decks_where_alias/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
      onSuccess: (data, values) => {
        /*         console.log("use_get_decks_where_alias/onSuccess: ", data) */
        data.data.forEach(singleDeck => {
          let singleDeckMod = {}

          if (typeof singleDeck["first"] === "number") {
            singleDeckMod = {
              ...singleDeck,
              likes: singleDeck["first"],
              created: singleDeck["second"],
            }
          } else {
            singleDeckMod = {
              ...singleDeck,
              likes: singleDeck["second"],
              created: singleDeck["first"],
            }
          }

          queryCache.setQueryData(
            ["initial_deck", String(singleDeck.deckRef.value.id)],
            singleDeckMod
          )
        })
      },
    }
  )
}

async function fetch_get_decks_where_alias(
  alias,
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext,
  after,
  withinDays,
  deckFormat,
  deckColor,
  cardsIncluded,
  cardsExcluded,
  orderByUDF
) {
  try {
    const response = await faunaQueries.QM_get_decks_where_alias(
      1,
      after,
      null,
      alias,
      withinDays,
      deckFormat,
      deckColor,
      cardsIncluded,
      cardsExcluded,
      orderByUDF
    )
    /*     console.log("fetch_get_decks_where_alias -> response", response) */
    return response
  } catch (err) {
    /*     console.log("fetch_get_decks_where_alias -> err", err) */
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
    throw err
  }
}

function use_get_user_info(
  queryCache,
  alias,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useQuery(
    [`QM_get_user_info`, alias],
    (prefix, key, after = null) =>
      fetch_get_user_info(
        queryCache,
        alias,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      staleTime: 0,
      /* keepPreviousData: true, */

      onError: (error, values, rollback) => {
        /*         console.log("use_get_user_info/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
    }
  )
}

async function fetch_get_user_info(
  queryCache,
  alias,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  try {
    const response = await faunaQueries.QM_get_user_info(alias)
    /*     console.log("fetch_get_user_info/response: ", response) */
    return response
  } catch (err) {
    /*     console.log("fetch_get_user_info -> err", err) */
    throw err
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
  }
}
